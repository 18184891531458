import axios from 'axios';
import { Validator } from 'vee-validate';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

export default class TranslationService {
  constructor(store, i18n) {
    this.store = store;
    this.i18n = i18n;
    this.vuetify = null;
  }

  /**
   * Evaluates is the language set in url or browser default supported, if so, use it, if not return default app language.
   */
  getSupportedLanguage() {
    const { hash } = document.location;
    const hashQuery = hash.substring(hash.indexOf('?'));
    const params = new URLSearchParams(hashQuery);
    const browserLang = params.get('langKey') || window.navigator.language.slice(0, 2);
    const storeLangs = this.store.getters.languages;
    if (storeLangs && Object.keys(storeLangs).indexOf(browserLang) !== -1) {
      return browserLang;
    }
    // final fallback - system settings language
    return this.store.getters.sysInfo.langKey;
  }

  /**
   * Support function to perform LAZY localization of validation messages.
   * @param {string} langKey - The language key.
   */
  localizeValidator(langKey) {
    // for sr use latin variant
    const lkey = langKey === 'sr' ? `${langKey}_Latin` : langKey;
    return import(`vee-validate/dist/locale/${lkey}.js`).then((locale) => {
      // all overrides (currently only two)
      locale.messages.required = this.i18n.t('g.form-validation.this_field_is_required');
      locale.messages.email = this.i18n.t('g.form-validation.email');
      Validator.localize(langKey, locale);
    });
  }

  /**
   * Support function to the localization of supported activities in sysInfo.
   */
  localizeActivities() {
    const { sysInfo } = this.store.getters;
    // initialize translations for activities
    sysInfo.activities.forEach((act) => {
      act.transl = this.i18n.t(`app.model.activity.${act.name}`);
    });
    this.store.commit('sysInfo', sysInfo);
  }

  /**
   * Set new language for application and re-initialize all i18n related libraries.
   * NOTE: the page logic to refresh the language in the URL is in app.component.js - in watch.
   * @param {string} newLanguage - The new language code.
   */
  async refreshTranslation(newLanguage) {
    console.log('refr');
    // let { currentLanguage } = this.store.getters;
    const currentLanguage = newLanguage || this.getSupportedLanguage();
    // this.i18n.setLocaleMessage(currentLanguage, {});
    if (this.i18n) {
      // clear local messages in order to re-init them
      if (!this.i18n.messages[currentLanguage]) {
        // fetch translations for not initialized languages
        const res = await axios.get(`api/i18n/${currentLanguage}`);
        if (res.data) {
          this.i18n.setLocaleMessage(currentLanguage, res.data);
        }
      }
      this.i18n.locale = currentLanguage;

      this.localizeActivities();

      // localize used libraries and plugins
      await this.localizeValidator(currentLanguage);
      if (this.vuetify) {
        this.vuetify.lang.current = currentLanguage;
      }

      // localize dayjs
      // import the locale dynamically with import
      const langConfig = await import(`dayjs/locale/${currentLanguage}.js`);
      // NOTE: correcting sr locale for dayjs that is not appropriate
      if (currentLanguage === 'sr') {
        dayjs.updateLocale('sr', {
          // force the two digits for hours
          formats: { LT: 'HH:mm' },
          // force names of months and days as lowercase
          weekdays: langConfig.weekdays.map((wd) => wd.toLowerCase()),
          weekdaysShort: langConfig.weekdaysShort.map((wd) => wd.toLowerCase()),
          weekdaysMin: langConfig.weekdaysMin.map((wd) => wd.toLowerCase()),
          months: langConfig.months.map((mon) => mon.toLowerCase()),
          monthsShort: langConfig.monthsShort.map((mon) => mon.toLowerCase()),
        });
      }
      // ... and localize for it
      // NOTE: this is important in order to use localized formats like LT, L...
      // console.log('Localizing dayjs for', currentLanguage);
      dayjs.locale(currentLanguage);

      // NOTE: commit the change after all other dependencies are set with new locale
      this.store.commit('currentLanguage', currentLanguage);
    }
  }

  /**
   * The array of supported languages with their translations, prepared for use on forms.
   */
  get appLanguages() {
    return Object.keys(this.store.getters.languages).map((langKey) => ({
      // not using language translation right now
      // text: this.i18n.t(`label.language.german`),
      text: this.store.getters.languages[langKey].name,
      value: langKey,
    }));
  }

  /**
   * Sets the Vuetify instance.
   * @param {object} instance - The Vuetify instance in the app.
   */
  set vuetifyInstance(instance) {
    this.vuetify = instance;
    // here is the right place for force localization of vuetfy
    this.vuetify.lang.current = this.i18n.locale;
  }
}
