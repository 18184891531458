import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import escapeRegExp from '../common/escapeRegExp';
// import Diacritics from 'diacritic';

import fmt from '../common/customFormatters';
import constants from '../common/constants';
import { deepGetByPath } from '../common/commonUtils';

const { toLocalizedTZ, localeCode } = fmt;
const { SEPARATOR_CHAR, SUPPORTED_LANGUAGES } = constants;

/**
 * List of HTML entities for escaping.
 */
const htmlEscapes = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '/': '&#x2F;',
};

// Regex containing the keys listed immediately above.
const htmlEscaper = /[&<>"'/]/g;

// Escape a string for HTML interpolation.
/**
 *
 * @param string
 */
function escapeHTML(string) {
  return `${string}`.replace(htmlEscaper, function (match) {
    return htmlEscapes[match];
  });
}

// convert ASCII to wildcard
// eslint-disable-next-line no-unused-vars
/**
 *
 * @param input
 */
function addAccents(input) {
  let retval = input;
  retval = retval.replace(/([ao])e/gi, '$1');
  retval = retval.replace(/ss/gi, 's');
  retval = retval.replace(/e/gi, '[eèéêë]');
  retval = retval.replace(/[cčć]/gi, '[cçčć]');
  retval = retval.replace(/i/gi, '[iîï]');
  retval = retval.replace(/u/gi, '[uùûü]');
  retval = retval.replace(/y/gi, '[yÿ]');
  retval = retval.replace(/[sš]/gi, '(ss|[sßșš])');
  retval = retval.replace(/t/gi, '([tţț])');
  retval = retval.replace(/a/gi, '([aàâäă]|ae)');
  retval = retval.replace(/o/gi, '([oôö]|oe)');
  retval = retval.replace(/[dđ]/gi, '[dđ]');
  retval = retval.replace(/[zž]/gi, '[zž]');
  return retval;
}

/**
 * The formatting support mixin.
 * @module mixins/sortMixin
 */
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {};
      },
      computed: {
        ...mapGetters(['sysInfo', 'currentLanguage']),
      },
      methods: {
        /**
         * @see fmt.localeCode
         */
        localeCode,

        /**
         * Formats date/time for technical display.
         * @function
         * @param {Date} value - The date to format.
         */
        fmtDateTime(value) {
          return toLocalizedTZ(value, this.$store.getters.currentLanguage).format('L, LT');
        },
        /**
         * Formats date as time only - localized.
         * @function
         * @param {Date} value - The date to format.
         * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
         */
        fmtTimeOnlyLocalized(value, tz) {
          return fmt.fmtTimeOnlyLocalized(value, this.$store.getters.currentLanguage, tz);
        },
        /**
         * Formats date with day only in human readable format.
         * @function
         * @param {Date} value - The date to format.
         * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
         */
        fmtDateDayOnlyHuman(value, tz = undefined) {
          return toLocalizedTZ(value, this.$store.getters.currentLanguage, tz).format('dddd');
        },
        /**
         * Formats date with date only in human readable format (short form).
         * @function
         * @param {Date} value - The date to format.
         * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
         */
        fmtDateOnlyHuman(value, tz = undefined) {
          const langKey = this.$store.getters.currentLanguage;

          let domFormat = 'D';
          if (langKey === 'sr') {
            domFormat = 'D.';
          }
          return toLocalizedTZ(value, langKey, tz).format(`${domFormat} MMM YYYY`);
        },
        /**
         * Formats date and time in custom format.
         * @function
         * @param {Date} value - The date to format.
         * @param {object} options - The formatting options.
         * @param {string} options.tz - The timezone string. If not specified, the locale tz will be used.
         * @param {string} options.format - The format template to use, default includes all.
         */
        fmtDateTimeCustom(value, { tz = undefined, format = 'dddd, D MMMM YYYY' }) {
          const langKey = this.$store.getters.currentLanguage;
          // NOTE: in order to support proper date formatting for some languages, the day of month will have variants depending on locale
          // NOTE: there is an issue #1140 to improve formatting according to grammar.
          let dtFormat = format;
          if (langKey === 'sr') {
            dtFormat = dtFormat.replace(' D ', ' D. ');
          }

          return toLocalizedTZ(value, langKey, tz).format(dtFormat);
        },
        /**
         * The mixin wrapper that uses common custom formatter.
         * @param {Date} value - The date/time. NOTE: the locale will be taken from the store.
         * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
         */
        fmtDateHuman(value, tz = undefined) {
          return fmt.fmtDateHuman(value, this.$store.getters.currentLanguage, tz);
        },

        /**
         * The mixin wrapper that uses common custom formatter.
         * @param {Date} value - The date/time. NOTE: the locale will be take from store.
         * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
         */
        fmtDateTimeHuman(value, tz = undefined) {
          return fmt.fmtDateTimeHuman(value, this.$store.getters.currentLanguage, tz);
        },

        /**
         * Formats the duration specified in minutes.
         * @param {number} value - The value of duration in minutes.
         * @param {string} unit - The unit in which the duration is expressed (moment standard), by default minutes.
         */
        fmtDurationHuman(value, unit = 'minutes') {
          // NOTE: using labels from translations bundle, because not able to configure localization for duration plugin
          // We need to prepare the custom formatting, because dayjs doesn't recognize the trim option...
          const formatHrs = `H [${this.$i18n.t('label.hours_short')}]`;
          const formatMins = `m [${this.$i18n.t('label.minutes_short')}]`;
          if (value <= 0) {
            return `0 ${this.$i18n.t('label.minutes_short')}`;
          }
          const dur = dayjs.duration(value, unit);
          const daysCount = Math.floor(dur.asDays());
          const formatDays = `${daysCount} [${this.$i18n.t('label.days_short')}]`;
          if (daysCount === 1 && dur.hours() === 0 && dur.minutes() === 0) {
            return `24 ${this.$i18n.t('label.hours_short')}`;
          }
          const space = dur.hours() && dur.minutes() ? ' ' : '';
          const spaceDays = (daysCount && dur.hours()) || (daysCount && dur.minutes()) ? ' ' : '';
          const formatAll = `${daysCount ? formatDays : ''}${spaceDays}${dur.hours() ? formatHrs : ''}${space}${
            dur.minutes() ? formatMins : ''
          }`;
          return dur.format(formatAll);
        },
        /**
         * Formats the holiday range (interface used for company and employees holidays).
         * @param {object} holiday - The object with common fields for any type of holiday: company, employee...
         * @param {Date} holiday.dateFrom - The date of holiday start.
         * @param {Date} holiday.dateTo - The date of holiday end.
         * @param {boolean} holiday.allDay - The flag that the holiday is for all day.
         * @param {string} tz - The timezone of holiday's company (city).
         * @returns {string} - The formatted holiday range.
         */
        fmtHolidayRange(holiday, tz) {
          const loc = this.currentLanguage;

          const today = dayjs().tz(tz).startOf('date');
          const dateFrom = toLocalizedTZ(holiday.dateFrom, loc, tz);
          const dateTo = toLocalizedTZ(holiday.dateTo, loc, tz);
          const { allDay } = holiday;
          const sameDay = dateFrom.isSame(dateTo, 'day');
          const sameMonth = dateFrom.isSame(dateTo, 'month');
          const sameYear = dateFrom.isSame(dateTo, 'year');
          // is the range in this year?
          const thisYear = sameYear && dateFrom.isSame(today, 'year');

          // const dateYearToSuffix = sameYear ? '' : ` ${yearToStr}`;
          let fmtResult;
          // NOTE: in order to support proper date formatting for some languages, the day of month will have variants depending on locale
          // NOTE: there is an issue #1140 to improve formatting according to grammar.
          let domFormat = 'D';
          if (loc === 'sr') {
            domFormat = 'D.';
          }

          if (sameDay) {
            if (allDay) {
              fmtResult = dateFrom.format(`${domFormat} MMMM${thisYear ? '' : ' YYYY'}`);
            } else {
              return `${dateFrom.format(`${domFormat} MMMM${thisYear ? '' : ' YYYY'}, LT`)} - ${dateTo.format('LT')}`;
            }
          } else if (allDay) {
            fmtResult = `${dateFrom.format(
              `${domFormat} ${sameMonth ? '' : 'MMMM'}${thisYear || sameYear ? '' : ' YYYY'}`,
            )} - ${dateTo.format(`${domFormat} MMMM${thisYear ? '' : ' YYYY'}`)}`;
          } else {
            fmtResult = `${dateFrom.format(`${domFormat} MMMM${thisYear ? '' : ' YYYY'}, LT`)} - ${dateTo.format(
              `${domFormat} MMMM${thisYear ? '' : ' YYYY'}, LT`,
            )}`;
          }
          return fmtResult;
        },
        /**
         * Formats the holiday time (from or to) based on holiday props (holiday - the interface used for company and employees holidays).
         * @param {object} holiday - The object with common fields for any type of holiday: company, employee...
         * @param {string} field - The name of the field to format: from or to.
         * @param {string} tz - The timezone of holiday's company (city).
         * @returns {string} - The formatted holiday time.
         */
        fmtHolidayTime(holiday, field, tz) {
          return holiday.allDay
            ? fmt.fmtDateHuman(holiday[field], this.$store.getters.currentLanguage, tz)
            : fmt.fmtDateTimeHuman(holiday[field], this.$store.getters.currentLanguage, tz);
        },

        /**
         * Multiplies with 100 and formats the service price (expressed in cents in the model) in the country defined locale, or detected locale.
         * @param {number} price - The price expressed in cents.
         * @param {object} options - The options for formatter.
         * @param {string|undefined} options.currency - The currency used with displayed number.
         * @param {object | undefined} options.country - The explicit, fall back country if the account is not logged in.
         * The country used to identify detailed formatting based on language and country.
         */
        fmtServicePrice(price, options = { currency: undefined, country: undefined }) {
          return fmt.fmtServicePrice(price, {
            currency: options.currency,
            // take country from account if logged in, otherwise from the explicit country
            country: this.$store.getters.account ? this.$store.getters.account.city.country : options.country,
            language: this.$store.getters.currentLanguage,
          });
        },
        /**
         * Extracts the localized service name (entire name, path + base path).
         * @param {object} service - The service DTO with initialized transl field for name property.
         * @param {object?} options - The options for building the lozalized service name.
         * @param {string?} options.langKey - The langKey to be used in order to try to force some language instead of currentLang.
         * @param {string?} options.defaultLangKey - The default lang key, eg on company level.
         * @param {boolean?} options.showB - The flag to display alternative service name eg stored with booking or overriden name from breq filter.
         * @returns {string} - The localized service name.
         */
        lzServiceName(service, options) {
          const langKey = options.langKey || this.$store.getters.currentLanguage;
          const opts = { defaultLangKey: langKey, showB: false, ...options };
          const { defaultLangKey, showB } = opts;

          const { bTranslName, translName } = service;

          if (bTranslName && showB) {
            // in some cases, the default lang of the company might been changed, so translation need to be searched
            let foundBTranslPerLang = bTranslName?.[langKey] || bTranslName?.[langKey];
            if (!foundBTranslPerLang) {
              // the translation for current user lang or company default lang is not found, now try with first available transl
              // in the booking, by searching through app-supported languages
              // NOTE: This will not solve the issue if some lang is removed from APP SUPPORTED LANGUAGES!
              const keyWithTransl = Object.keys(SUPPORTED_LANGUAGES).find((suppLangKey) => bTranslName[suppLangKey]);
              foundBTranslPerLang = bTranslName[keyWithTransl];
            }
            return foundBTranslPerLang;
          }

          return translName?.[langKey] || translName?.[defaultLangKey] || '';
        },

        /**
         * Extracts the localized path of service name through categories by using standard separator.
         * @param {object} service - The service DTO with initialized transl field for name property.
         * @param {object?} options - The options for building the lozalized service path.
         * @param {string?} options.langKey - The langKey to be used in order to try to force some language instead of currentLang.
         * @param {string?} options.defaultLangKey - The default lang key, eg on company level.
         * @param {boolean?} options.clientView - The flag that indicates that the displayed translated name should be the one of actual booking of breq filter,
         * not current service name. This is possible only when service item / slot item has bTransl field.
         * @param {boolean?} options.appendSeparator - Append separator at the end.
         * @returns {string} - The name of parent - path.
         */
        lzServiceNamePath(service, options = undefined) {
          const opts = { appendSeparator: false, clientView: false, ...options };
          const { appendSeparator, clientView, ...otherOpts } = opts;
          const serviceName = this.lzServiceName(service, { ...otherOpts, showB: options.clientView });
          const segments = serviceName.split(SEPARATOR_CHAR);
          segments.pop();
          const namePath = segments.join(SEPARATOR_CHAR);
          if (namePath) {
            // append separator at the end if requested
            return namePath.concat(appendSeparator ? SEPARATOR_CHAR : '');
          }
          return namePath;
        },

        /**
         * Extracts the localized base service name (without parent path).
         * @param {object} service - The service DTO with initialized transl field for name property.
         * @param {object} options - The options for building the lozalized service name.
         * @param {string?} options.langKey - The langKey to be used in order to try to force some language instead of currentLang.
         * @param {string?} options.defaultLangKey - The default lang key, eg on company level.
         * @param {boolean?} options.clientView - The flag that indicates that the displayed translated name should be the one of actual booking of breq filter,
         * not current service name. This is possible only when service item / slot item has bTransl field.
         * @returns {string} - The base name - without path.
         */
        lzServiceNameBase(service, options) {
          const serviceName = this.lzServiceName(service, { ...options, showB: options.clientView });
          return serviceName.split(SEPARATOR_CHAR).pop();
        },

        /**
         * Builds the common employee map based on the provided list of employee ids.
         * The map is multi purpose, can contain a reusable data about employee accessible by employee id.
         * @param {Array<object>} employees - The array of employees or DTOs. The field id is required.
         * @returns {object} - The map of adjustments, settings for each employee id.
         */
        buildEmployeeMap(employees) {
          const employeesSorted = employees.sort((a, b) => a.id - b.id);
          return employeesSorted.reduce((acc, empl, index) => {
            acc[empl.id] = {
              /**
               * The employee's maturity index.
               */
              index,
              name: empl.name,
              /**
               * Assigned color to employee based on his id, creation date, maturity...
               */
              color: empl.color,
              qmsNA: empl.qmsNA,

              /**
               * Deleted flag. Can be based on deletedAt field or some dto field deleted.
               */
              deleted: !!empl.deletedAt || !!empl.deleted,
            };
            return acc;
          }, {});
        },

        /**
         * Returns the name of icon used to represent the resource type.
         * @param {string} resType - The enumerated resource type. See API.
         */
        resTypeIcon(resType) {
          switch (resType) {
            case 'PHYSICAL':
              return 'noise_control_off';
            case 'C-AND':
              return 'hub';
            case 'C-OR':
              return 'spoke';
            default:
              return '';
          }
        },

        /**
         * Creates the CSV out of items by specified item property.
         * @param {Array<object>} items - The items.
         * @param {string} property - The property to extract from the item. Can be a dot-separated path to property.
         */
        itemsToCsv(items, property) {
          return items.map((item) => deepGetByPath(item, property)).join(', ');
        },

        /**
         * The simple, quick helper used to minimize html for UI and small management purposes on client side.
         * Taken from yairEO/minifyHTML.js gist.
         * @param {string} s - The html string.
         * @returns {string} - The minimized html.
         */
        minifyHtml(s) {
          return s
            .replace(/>[\r\n ]+</g, '><')
            .replace(/(<.*?>)|\s+/g, (m, $1) => $1 || ' ')
            .trim();
        },

        /**
         * Draft support function for converting new lines in text to html breaks.
         * NOTE: currently br is used.
         * @param {string} text - The text to modify.
         * @returns {string} - The modified text.
         */
        textToHtmlBreaks(text) {
          return text
            .split(/\r\n|\r|\n/)
            .map((line) => `${line}<br/>`)
            .join('');
        },

        /**
         * Prepares the regex for matching multiple subkeys (words) in the search key, escapes it and prepares for search.
         * @param {string} skey - The search key.
         * @returns {string} - The preapared RegEx for matching at least one of multiple words.
         */
        getSearchWordsRegex(skey) {
          return escapeRegExp((skey || '').trim())
            .split(/\s+/g)
            .filter((segKey) => segKey)
            .map((segKey, keyIndex) => `(?<g${keyIndex}>${addAccents(segKey)})`)
            .join('|');
        },

        /**
         * Marks the text for highlighting by adding marker classes in spans.
         * The search key is split into several sub-keys and matched accordingly with different color.
         * @param html
         * @param skey
         */
        markForHighlight(html, skey) {
          if (!html) return '';

          // split the key by space
          const subKeys = this.getSearchWordsRegex(skey);

          // const prepHtml = escapeHTML(html);

          // console.log(subKeys);
          let hlResult = escapeHTML(html);

          hlResult = hlResult.replace(new RegExp(subKeys, 'gi'), (match, ...args) => {
            // const hasNamedGroups = typeof args.at(-1) === 'object';
            // const offset = hasNamedGroups ? args.at(-3) : args.at(-2);
            const groups = args.at(-1);
            // return `${match} (${offset}) `;
            // console.log(
            //   groups,
            //   Object.values(groups).findIndex((gval) => gval === match),
            // );
            const matchedKeyIndex = Object.values(groups).findIndex((gval) => gval === match);
            return `<span class="c-hl_text clr-${matchedKeyIndex % 5}">${match}</span>`;
          });

          // subKeys.forEach((subKey, keyIndex) => {
          //   hlResult = hlResult.replace(new RegExp(addAccents(subKey), 'gi'), (match) => {
          //     return `<span class="c-hl_text col-${keyIndex % 3}">${match}</span>`;
          //   });
          // });

          return hlResult;
        },
        /**
         * Support function to convert the case.
         * @param {string} str - The string to convert.
         * @returns {string} - The snake case string.
         */
        camelToSnakeCase(str) {
          return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
        },

        /**
         * Performs entity translation based on mini translation bundle and the translation key.
         * The language is identified based on current language.
         * @param {object} bundle - The translation bundle defined by parts of transl schema.
         * @param {string} key - The translation key.
         * @returns {string} - The translated key.
         */
        entityTransl(bundle, key) {
          if (bundle && bundle[key] && bundle[key][this.currentLanguage]) {
            return bundle[key][this.currentLanguage];
          }
          return '';
        },
      },
    });
  },
};
